import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import MortgageCalculator from "../../components/MortgageCalculator";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Bankgarantie = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Die Bankgarantie bei Immobilienkrediten – wozu ist sie gut?" showCalc={false} />
            <Article>
                <p>
                    Es ist nicht immer einfach, bei Vertragsabschluss einzuschätzen, ob eine Person den Verpflichtungen
                    eines Kredits wirklich nachkommen kann. Dieses Risiko kann für den Gläubiger deshalb durch eine
                    Bankgarantie abgesichert werden. Wenn es dann zu einem Zahlungsausfall auf deiner Seite kommt,
                    springt also die Bank für dich ein. Aber es gibt unterschiedliche Arten von Bankgarantien und sie
                    eignet sich nicht für jeden gleich gut. Außerdem ist sie mit Kosten verbunden. In diesem Artikel
                    wollen wir uns dieses Thema mal genauer ansehen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist eine Bankgarantie?</h2>
                <p>
                    Im Wirtschaftsleben ist eine Bankgarantie durchaus üblich, im privaten Bereich hingegen eher
                    ungewöhnlich. Der Grund dafür ist, dass bei Abschluss eines{" "}
                    <Link to="/artikel/wohnkredit/" target="_blank" rel="noreferrer noopener">
                        Wohnkredits
                    </Link>{" "}
                    die Bank normalerweise selbst der Gläubiger ist, wodurch sich eine Bankgarantie erübrigt. Das heißt,
                    dass eine Bankgarantie im privaten Bereich erst interessant wird, wenn ein dritter Gläubiger
                    beteiligt ist. Das ist zum Beispiel bei Herstellern von Fertighäusern der Fall, die eine
                    Bankgarantie fordern können.
                </p>
                <p>
                    Ein anderer Fall, in dem die Bankgarantie zum Einsatz kommen kann, ist die Mietkaution. Sie wird
                    zwar meistens über Sparbücher abgewickelt, kann aber für die Vertragspartner auch durch eine
                    Bankgarantie abgesichert werden.
                </p>
                <p>
                    Eine Bankgarantie ist also grundsätzlich nichts anderes, als ein Sicherungsinstrument bei
                    Zahlungsausfall eines Schuldners gegenüber eines Gläubigers. Die Bankgarantie soll finanzielle
                    Schäden von Dritten ausgleichen, also den Gläubiger absichern. Hier dient uns wieder das Beispiel
                    des Herstellers eines Fertighauses zur Erklärung: Wenn der Vertrag unterschrieben wird, wird das
                    Fertighaus produziert. Mit der Bankgarantie hat der Produzent dann die Sicherheit, dass er sein Geld
                    bekommt. Wenn der Schuldner bzw. die Schuldnerin ihre Leistung bei Fertigstellung des Hauses nicht
                    mehr erbringen kann, dh. nicht mehr zahlen kann, kann der Gläubiger die vertraglich festgelegte
                    Summe dank der Bankgarantie von der Bank beanspruchen. Er muss lediglich der Bank schreiben und das
                    vereinbarte Geld verlangen. Aber dadurch ist die Sache für dich als Kreditnehmer oder Kreditnehmerin
                    noch nicht erledigt! Denn der durch die Bank getilgte Betrag wird in Zukunft dem Kunden bzw. der
                    Kundin – also dir – angelastet und muss später durchaus von dir bezahlt werden. Ob sich eine
                    Bankgarantie also lohnt, musst du dir vorher gut überlegen, denn sie ist auch mit Kosten verbunden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Finanzberatung anfordern
                    </a>
                </p>
                <hr />

                <h2>Wann bekomme ich eine Bankgarantie? Die Voraussetzungen</h2>
                <p>
                    Die Bankgarantie kann man im Endeffekt als eine Art Zahlungsaufschub sehen. Deshalb ist auch klar,
                    dass deine{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    , also deine Kreditwürdigkeit, eine wesentliche Voraussetzung dafür ist, dass du eine Bankgarantie
                    bekommst. Denn auch die Bank möchte sich natürlich absichern. Die Voraussetzung für eine
                    Bankgarantie ist ein Abschluss eines Garantiekreditvertrages. Dieser Haftungskredit unterliegt den
                    gleichen Vorschriften und Bedingungen wie allen anderen Kreditgewährungen (Bonitätsprüfung,
                    Sicherheiten, usw.). Im Garantiekreditvertrag bestimmt man dann die{" "}
                    <Link to="/artikel/konditionen/" target="_blank" rel="noreferrer noopener">
                        Konditionen
                    </Link>{" "}
                    für die Haftung genau.
                </p>
                <hr />

                <h2>Die Kosten einer Bankgarantie</h2>
                <p>
                    Normalerweise haben Banken bereits Sondervereinbarungen und Pauschalsätze mit bestimmten
                    Fertigteilhaus-Herstellern. Frag deine Bank einfach nach den Konditionen für die Garantie und
                    überlege dir danach, ob sich der finanzielle Aufwand für dich lohnt. Es ist wichtig, jeden
                    Einzelfall genau zu betrachten und sich gut beraten zu lassen, bevor man sich auf eine Bankgarantie
                    einlässt. Denn es gibt auch Alternativen, wie die unwiderrufliche Zahlungsanweisung (UZA).
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Erstgespräch vereinbaren
                    </a>
                </p>
                <p>
                    Ob du einer Bankgarantie zustimmst, solltest du dir auf jeden Fall vorher sehr gut überlegen. In
                    Geschäften zwischen Unternehmen sind solche Garantien zwar auch immer ein bisschen ungern gesehen,
                    weil sie Misstrauen kommunizieren, aber eine wichtige Absicherung für beide. Im privaten Bereich,
                    wie beim Immobilienkauf, sind sie eher selten und sollten deshalb immer gut überlegt sein. Wenn du
                    dir unsicher bist, melde dich bei einem unserer Finanzexperten für ein kostenloses Erstgespräch.
                    Unsere Experten können dich auf mögliche Risiken aufmerksam machen, oder sie finden mit dir andere
                    Lösungen, die für dich am Ende die bessere Alternative sind.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"bankgarantie"}></BreadcrumbList>
            <ArticleStructuredData
                page={"bankgarantie"}
                heading={"Die Bankgarantie bei Immobilienkrediten – wozu ist sie gut?"}
            />
        </Layout>
    );
};

export default Bankgarantie;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.bankgarantie", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
